import React, { useState, useRef } from 'react';
import '../css/EmojiInput.css';

const EmojiInput = ({ startingValue, onInput }) => {
  const [emoji, setEmoji] = useState(`:${startingValue}:`); // Initialize with colons
  const inputRef = useRef(null);

  const handleChange = (e) => {
    let value = e.target.value;

    // Add colons at either end if they're missing
    if (value[0] !== ':') {
      value = ':' + value;
    }
    if (value[value.length - 1] !== ':') {
      value = value + ':';
    }

    setEmoji(value);

    onInput(value.substring(1, value.length - 1));
  };

  const handleKeyDown = (e) => {
    const cursorPos = inputRef.current.selectionStart;
    const cursorEnd = inputRef.current.selectionEnd;
    if(e.key === 'Backspace'){
      //prevent backspacing first colon
      if (cursorPos <= 1){
        e.preventDefault();
        inputRef.current.selectionStart = 1;
        inputRef.current.selectionEnd = 1;
      }
    } else {
      //prevent typing before first colon
      if (cursorPos === 0){
        e.preventDefault();
        inputRef.current.selectionStart = 1;
        inputRef.current.selectionEnd = 1;
      }
    }
    //prevent typing ir backspacing after last colon
    if(cursorEnd === emoji.length){
      e.preventDefault();
      inputRef.current.selectionStart = emoji.length - 1;
      inputRef.current.selectionEnd = emoji.length - 1;
    }
  };

  return (
    <input
      type="text"
      value={emoji}
      onChange={handleChange}
      className="emoji-input"
      onKeyDown={handleKeyDown}
      ref={inputRef}
    />
  );
};

export default EmojiInput;
