import React from 'react';
import '../css/ToggleSwitch.css';

const ToggleSwitch = ({ isToggled, onToggle }) => {
  return (
    <label className="switch">
      <input 
        type="checkbox" 
        checked={isToggled}
        onChange={onToggle}
      />
      <span className="switch-slider"></span>
    </label>
  );
};

export default ToggleSwitch;
