import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes 
} from 'react-router-dom';
import { UserProvider } from './contexts/UserContext';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Guilds from './components/Guilds';
import Guild from './components/Guild';
import Profile from './components/Profile';
import NotFound from './components/NotFound';

function App() {
  useEffect(() => {
    document.title = 'Aineko: The Discord Bot';
  }, []);

  return (
    <UserProvider>
      <div className="App">
        <Router>
          <Header/>
          <div className="content grow">
            <Routes >
              <Route index element={<Home />} />
              <Route path='/guilds' element={<Guilds />}/>
              <Route path='/guild/:id' element={<Guild />}/>
              <Route path='/profile' element={<Profile />}/>
              <Route path='*' element={<NotFound />}/>
            </Routes >
          </div>
          <Footer/>
        </Router>
      </div>
    </UserProvider>
  );
}

export default App;