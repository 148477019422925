import { useRef, useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import { formatDate } from '../utils/dateUtils';
import MultiRangeSlider from '../components/MultiRangeSlider';
import '../css/Profile.css';
import defaultAvatar from '../assets/images/default-avatar.jpg';

const Profile = () => {
    let maxTime = new Date().getTime();

    const { user } = useContext(UserContext);
    let [minTime, setMinTime] = useState(0);
    const [scritchData, setScritchData] = useState(null);
    const [stonksData, setStonksData] = useState(null);
    const [startTime, setStartTime] = useState(minTime); // in milliseconds
    const [endTime, setEndTime] = useState(maxTime); // current time in milliseconds
  
    // Ref to access the canvas element
    const canvasRef = useRef(null);

    const handleSliderChange = (newValues) => {
        setStartTime(newValues[0]);
        setEndTime(newValues[1]);
    };

    useEffect(() => {
        if (!user) {
            setScritchData(null);
            return;
        }

        const fetchScritchData = async () => {
            try {
              const response = await fetch(`/api/scritch_data`);
              const data = await response.json();
              setScritchData(data);
            } catch (err) {
              console.error(err);
              setScritchData(null);
            }
        };
      
        fetchScritchData();
    }, [user]);

    // Fetch stonks data (user_scritch)
    useEffect(() => {
        if (!user) {
            setStonksData(null);
        return;
        }
    
        const fetchStonksData = async () => {
        try {
            const response = await fetch(`/api/user_scritch`);
            const data = await response.json();
            setStonksData(data);

            // Set the startTime to the first entry time from the database if available
            if (data.length > 0) {
                const earliestTime =new Date(data[0].timestamp).getTime()
                setMinTime(earliestTime);
                setStartTime(earliestTime);
            }
    
        } catch (err) {
            console.error(err);
            setStonksData(null);
        }
        };
    
        fetchStonksData();
    }, [user]);

    // Draw the stonks graph
    useEffect(() => {
      if (stonksData && canvasRef.current) {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        
        ctx.strokeStyle = '#001b00';        
        ctx.fillStyle = 'limegreen';
        ctx.font = '15px sans-serif';

        ctx.clearRect(0,0,canvas.width,canvas.height);
        

        let startScritch = 100;
        let largestDifference = 0;
        for (const scritch of stonksData){
            const scritchTime = new Date(scritch.timestamp).getTime();
            if(scritchTime > endTime) break;
            if(scritchTime < startTime){
                startScritch = scritch.amount;
            } else {
                const difference = Math.abs(startScritch - scritch.amount);
                largestDifference = (difference > largestDifference) ? difference : largestDifference;
            }
        }

        const xUnits = 600/(endTime-startTime);
        let prevTime = startTime;

        let scale = largestDifference * 1.2;
        if(scale < 48){
            scale = 48;
            largestDifference = 40
        }
        const yUnits = 120/scale;
        let prevAmount = startScritch;

        //draw horizontal guides
        let value = Math.round(startScritch+largestDifference);
        let sign = (value < 0) ? '-' : '';
        ctx.beginPath();
        ctx.moveTo(20, 44);
        ctx.lineTo(620, 44);
        ctx.fillText(`${sign}ฅ${Math.abs(value)}`, 625, 44);

        value = Math.round(startScritch+largestDifference/2);
        sign = (value < 0) ? '-' : '';
        ctx.moveTo(20, 104);
        ctx.lineTo(620, 104);
        ctx.fillText(`${sign}ฅ${Math.abs(value)}`, 625, 104);

        value = Math.round(startScritch);
        sign = (value < 0) ? '-' : '';
        ctx.moveTo(20, 164);
        ctx.lineTo(620, 164);
        ctx.fillText(`${sign}ฅ${Math.abs(value)}`, 625, 164);

        value = Math.round(startScritch-largestDifference/2);
        sign = (value < 0) ? '-' : '';
        ctx.moveTo(20, 224);
        ctx.lineTo(620, 224);
        ctx.fillText(`${sign}ฅ${Math.abs(value)}`, 625, 224);

        value = Math.round(startScritch-largestDifference);
        sign = (value < 0) ? '-' : '';
        ctx.moveTo(20, 284);
        ctx.lineTo(620, 284);
        ctx.fillText(`${sign}ฅ${Math.abs(value)}`, 625, 284);
        ctx.stroke();
        
        //draw vertical
        if(endTime - startTime > 172800000){
            ctx.fillText(new Date(startTime).toLocaleDateString(), 20, 328);
            ctx.fillText(new Date(startTime+(endTime - startTime)/4).toLocaleDateString(), 140, 328);
            ctx.fillText(new Date(startTime+(endTime - startTime)/2).toLocaleDateString(), 290, 328);
            ctx.fillText(new Date(startTime+(endTime - startTime)/4*3).toLocaleDateString(), 440, 328);
            ctx.fillText(new Date(endTime).toLocaleDateString(), 580, 328);
        } else {
            ctx.fillText(new Date(startTime).toLocaleString(), 20, 328);
            ctx.fillText(new Date(endTime).toLocaleString(), 500, 328);
        }
        ctx.beginPath();
        ctx.moveTo(170, 20);
        ctx.lineTo(170, 308);

        ctx.moveTo(320, 20);
        ctx.lineTo(320, 308);

        ctx.moveTo(470, 20);
        ctx.lineTo(470, 308);
        ctx.stroke();

        //drawing graph
        let x = 20;
        let y = 164
        let nextX, nextY;

        ctx.strokeStyle = 'limegreen';
        ctx.beginPath();
        ctx.moveTo(x, y);

        for(let i = 0; i < stonksData.length; i++){
            const scritchTime = new Date(stonksData[i].timestamp).getTime();
            if(scritchTime < startTime) continue;
            if(scritchTime> endTime) break;
            nextX = x + (scritchTime - prevTime) * xUnits;
            prevTime = scritchTime;
            ctx.lineTo(Math.round(nextX), Math.round(y));
            x = nextX;
            nextY = y - (stonksData[i].amount - prevAmount) * yUnits;
            prevAmount = stonksData[i].amount;
            ctx.lineTo(Math.round(x), Math.round(nextY));
            y = nextY;
        }

        ctx.lineTo(620, y);
        ctx.stroke();
  
        // Draw X and Y axis      
        ctx.beginPath();
        ctx.moveTo(20, 20);
        ctx.lineTo(20, 308);
        ctx.lineTo(620, 308);
        ctx.lineTo(620, 20);
        ctx.lineTo(20, 20);
        ctx.stroke();
      }
    }, [stonksData, startTime, endTime]);

    let profile;
    if (user){
        if(user.inDB && scritchData) {
            const joinDate = new Date(scritchData.join_time);
            const joinString = formatDate(joinDate);
            
            const avatarUrl = user.avatar
            ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`
            : defaultAvatar;
            profile = <div className="profile-container">
                <img className="profile-avatar" src={avatarUrl} alt={`${user.username} name`}></img>
                <div className="left">
                    <h2>{user.username}</h2>
                    <div>Aineko User Since: {joinString}</div>
                    <div>Scritch Bucks: {scritchData.scritch_bucks}</div>
                    <div>Scritch Bucks Highscore: {scritchData.scritch_bucks_highscore}</div>
                    <div>Scritches given: {scritchData.scritches}</div>
                </div>
            </div>
        } else {
            profile = <>
                <h2>Username: {user.username}</h2>
                <p>You haven't joined any guilds that Aineko is in.</p>
            </>;
        }
    } else {
        profile = <p>You are not signed in.</p>;
    }

    let stonksGraph;
    if(stonksData){
        stonksGraph = <div className="center stonks-container">
            <h3>Scritch Bucks Graph</h3>
            <canvas ref={canvasRef} className="center stonks-canvas" width="720" height="360"></canvas>
            <MultiRangeSlider min={minTime} max={maxTime} onChange={handleSliderChange} />
        </div>
    } else {
        stonksGraph = <div className="center stonks-container">
            <h3>No Scritch Data</h3>
            <canvas className="center stonks-canvas" width="720" height="360"></canvas>
        </div>
    }

    return (
        <>
            {profile}
            {stonksGraph}
        </>
    );
};

export default Profile;