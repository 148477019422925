import { Link } from "react-router-dom";
import { useEffect, useState, useRef, useContext} from 'react';
import { UserContext } from '../contexts/UserContext';
import {ReactComponent as Logo} from '../assets/images/logo.svg';
import defaultAvatar from '../assets/images/default-avatar.jpg';
import '../css/Header.css';

export default function Header(){
    const { user, clearUser } = useContext(UserContext);
    const dropdownRef = useRef(null);

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        }
    
        // Attach the listeners on component mount.
        document.addEventListener("mousedown", handleClickOutside);
        // Detach the listeners on component unmount.
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);
    
    const handleLinkClick = () => {
        setDropdownOpen(false);  // Close the dropdown
    };

    const handleLogin = async () => {
        window.location.href=process.env.NODE_ENV === 'production' ? 'https://aineko.gg/api/auth' : 'http://localhost:3001/api/auth'
    };

    const handleLogout = async () => {
        try {
            await fetch('/api/logout', { method: 'POST'});
            clearUser();
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    let login;

    if (user) {
        const avatarUrl = user.avatar
            ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`
            : defaultAvatar;
        login = <>
            <button className="user-dropdown" onClick={toggleDropdown}>
                <img src={avatarUrl} alt="Profile" className="user-pfp"/>
                <i className="icon-chevron-down"></i>
            </button>
            {dropdownOpen && (
                <div  ref={dropdownRef} className="dropdown-menu">
                    <Link to="/profile" className="dropdown-item" onClick={handleLinkClick}>Your Profile</Link>
                    <hr/>
                    <Link to="/guilds" className="dropdown-item" onClick={handleLinkClick}>Your Guilds</Link>
                    <hr/>
                    <Link to="https://discord.gg/34m84s9KHS" className="dropdown-item" onClick={handleLinkClick}>Discord Server</Link>
                    <hr/>
                    <Link
                        to="https://discord.com/api/oauth2/authorize?client_id=974462970220408902&permissions=8&scope=bot%20applications.commands"
                        className="dropdown-item"
                        onClick={handleLinkClick}
                    >Add to Server</Link>
                    <hr/>
                    <div onClick={handleLogout} className="dropdown-item">Logout</div>
                </div>
            )}
        </>
    } else {
        login = <button onClick={handleLogin} className="login transition-all">
            Login With Discord
        </button>;
    }

    return (
        <header className="header">
            <Link to="/" className="home-link">
                <Logo className="logo"/>
                <h1 className="title">Aineko</h1>
            </Link>
            <div className="spacer"></div>
            {login}
        </header>
    )
}