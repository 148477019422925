import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from '../contexts/UserContext';
import axios from 'axios';
import ToggleSwitch from './ToggleSwitch';
import EmojiInput from './EmojiInput';
import '../css/Guild.css';
import defaultGuildIcon from '../assets/images/default-guild-icon.jpg';

const Guild = () => {
    const { user } = useContext(UserContext);
    let params = useParams();
    const [channels, setChannels] = useState([]);
    const [roles, setRoles] = useState([]);

    //basic form states
    const [countChannel, setCountChannel] = useState('0');
    const [questionChannel, setQuestionChannel] = useState('0');
    const [championRole, setChampionRole] = useState('0');
    const [regularRole, setRegularRole] = useState('0');
    const [memberRole, setMemberRole] = useState('0');
    const [removeMembers, setRemoveMembers] = useState(false);
    const [basicErrorMessage, setBasicErrorMessage] = useState(null);
    const [basicSuccessMessage, setBasicSuccessMessage] = useState(null);
    
    //welcome message states
    const [isWelcomeEnabled, setIsWelcomeEnabled] = useState(false)
    const [welcomeMessage, setWelcomeMessage] = useState("");
    const [welcomeChannel, setWelcomeChannel] = useState("");
    const [welcomeImage, setWelcomeImage] = useState("");
    const [welcomeErrorMessage, setWelcomeErrorMessage] = useState(null);
    const [welcomeSuccessMessage, setWelcomeSuccessMessage] = useState(null);
    
    const [roleMenus, setRoleMenus] = useState([]);
    const [menuErrorMessage, setMenuErrorMessage] = useState(null);
    const [menuSuccessMessage, setMenuSuccessMessage] = useState(null);
    const [emojiErrorMessage, setEmojiErrorMessage] = useState(null);
    const [roleErrorMessage, setRoleErrorMessage] = useState(null);
    
    useEffect(() => {
        if (user && params.id) {
            fetch(`/api/guild/${params.id}`)
            .then(res => res.json())
            .then(data => {
                setChannels(data.channels);
                setRoles(data.roles);

                setCountChannel(data.count_channel || '0');
                setQuestionChannel(data.question_channel || '0');
                setChampionRole(data.champion_role_id || '0');
                setRegularRole(data.regular_role_id || '0');
                setMemberRole(data.member_role_id || '0');
                setRemoveMembers(data.remove_members || false);

                setIsWelcomeEnabled(data.welcome_on === 1);
                setWelcomeMessage(data.welcome_message || "");
                setWelcomeChannel(data.welcome_channel || data.channels[0].id);
                setWelcomeImage(data.welcome_image || "");
    
                if (data.menus && data.menus.length > 0) {
                    setRoleMenus(data.menus);
                }
            })
            .catch(err => console.error(err));
        }
    }, [user, params.id]);

    
    
    const handleCountChannelChange = (e) => {
        setCountChannel(e.target.value);
    };    
    const handleQuestionChannelChange = (e) => {
        setQuestionChannel(e.target.value);
    };    
    const handleChampionRoleChange = (e) => {
        setChampionRole(e.target.value);
    };
    const handleRegularRoleChange = (e) => {
        setRegularRole(e.target.value);
    };
    const handleMemberRoleChange = (e) => {
        setMemberRole(e.target.value);
    };
    const toggleRemoveMembers = async () => {
        setRemoveMembers(!removeMembers);
    };
    const basicSubmit = async (e) => {
        e.preventDefault();
        
        const payload = {
            guild_id: params.id,
            count_channel: countChannel,
            question_channel: questionChannel,
            champion_role_id: championRole,
            regular_role_id: regularRole,
            member_role_id: memberRole,
            remove_members: removeMembers
        };
        
        try {
            await axios.post('/api/basic-guild', payload)
                .then(() => {
                    setBasicSuccessMessage("Successfully saved the basic settings.");
                    setBasicErrorMessage(null);
                    // Hide the message after 3 seconds
                    setTimeout(() => {
                        setBasicSuccessMessage(null);
                    }, 3000);
                })
                .catch(error => {
                    // Check if the error response contains a custom message from the server
                    if (error.response && error.response.data && error.response.data.message) {
                        setBasicErrorMessage(error.response.data.message);
                        // Hide the message after 3 seconds
                        setTimeout(() => {
                            setBasicErrorMessage(null);
                        }, 3000);
                    } else {
                        // Default error message if none provided from the server
                        setBasicErrorMessage("Failed to save the basic settings.");
                        // Hide the message after 3 seconds
                        setTimeout(() => {
                            setBasicErrorMessage(null);
                        }, 3000);
                    }
                    setBasicSuccessMessage(null);
                });
        } catch (error) {
            console.error("An error occurred while saving the basic settings:", error);
        }
    };
    


    const toggleWelcomeMessage = async () => {
        if(!params.id) return;

        if(isWelcomeEnabled){
            setIsWelcomeEnabled(false);
            const payload = {
                guild_id: params.id,
                welcome_on: 0
            };
            
            try {
                await axios.post('/api/welcome-message', payload);
            } catch (error) {
                console.error("An error occurred while saving the welcome message:", error);
            }
            
            setWelcomeSuccessMessage(null);
            setWelcomeErrorMessage(null);
        } else{
            setIsWelcomeEnabled(true);
        }
    };
    const handleWelcomeMessageChange = (e) => {
        setWelcomeMessage(e.target.value);
    };
    const handleWelcomeChannelChange = (e) => {
        setWelcomeChannel(e.target.value);
    };
    const handleWelcomeImageChange = (e) => {
        setWelcomeImage(e.target.value);
    };
    const welcomeSubmit = async (e) => {
        e.preventDefault();
        
        const payload = {
            guild_id: params.id,
            welcome_on: isWelcomeEnabled ? 1 : 0,
            welcome_message: welcomeMessage,
            welcome_channel: welcomeChannel,
            welcome_image: welcomeImage
        };
        
        try {
            await axios.post('/api/welcome-message', payload)
                .then(() => {
                    setWelcomeSuccessMessage("Successfully saved the welcome message.");
                    setWelcomeErrorMessage(null);
                    // Hide the message after 3 seconds
                    setTimeout(() => {
                        setWelcomeSuccessMessage(null);
                    }, 3000);
                })
                .catch(error => {
                    // Check if the error response contains a custom message from the server
                    if (error.response && error.response.data && error.response.data.message) {
                        setWelcomeErrorMessage(error.response.data.message);
                        // Hide the message after 3 seconds
                        setTimeout(() => {
                            setWelcomeErrorMessage(null);
                        }, 3000);
                    } else {
                        // Default error message if none provided from the server
                        setWelcomeErrorMessage("Failed to save the welcome message.");
                        // Hide the message after 3 seconds
                        setTimeout(() => {
                            setWelcomeErrorMessage(null);
                        }, 3000);
                    }
                    setWelcomeSuccessMessage(null);
                });
        } catch (error) {
            console.error("An error occurred while saving the welcome message:", error);
        }
    };

    const handleAddMenu = async () => {
        const newMenus = [
            ...roleMenus,
            {
                channel: channels[0].id,
                type: 'color',
                buttons: [{
                    emoji: 'heart',
                    roleId: roles[roles.length-1].id
                }],
                exclusive: false,
                addMember: true,
                colorMessage: 'Choose a name color bellow to agree to the rules.',
                changed: true
            }
        ];
        setRoleMenus(newMenus);
    };
    const handleRoleMenuSelectChange = (event, index) => {
        // Create a copy of the roleMenus array
        const newMenus = [...roleMenus];
        
        // Update the channel value for the selected menu
        newMenus[index].channel = event.target.value;
        
        // Update the state
        setRoleMenus(newMenus);
    };
    const handleRoleMenuTypeChange = (event, index) => {
        // Create a copy of the roleMenus array
        const newMenus = [...roleMenus];
        
        // Update the type value for the selected menu
        newMenus[index].type = event.target.value;
        
        // Update the state
        setRoleMenus(newMenus);
    };
    const handleRemoveMenu = async (index, messageId) => {    
        try {
            if(messageId){
                // Then make an API call to remove the menu and delete the corresponding Discord message.
                const response = await axios.delete(`/api/remove-menu`, {
                    data: { 
                        guildId: params.id,
                        messageId: messageId
                    }
                });
        
                if (response.status === 200) {
                    // Remove from local state first for immediate UI feedback.
                    const newMenus = roleMenus.filter((_, i) => i !== index);
                    setRoleMenus(newMenus);
                }
            } else {
                // Remove from local state first for immediate UI feedback.
                const newMenus = roleMenus.filter((_, i) => i !== index);
                setRoleMenus(newMenus);
            }
        } catch (error) {
            setMenuErrorMessage('Failed to remove menu and message(s)');
            // Hide the message after 3 seconds
            setTimeout(() => {
                setMenuErrorMessage(null);
            }, 3000);
        }
    };
    const handleColorMessage = (event, index) => {
        // Create a copy of the roleMenus array
        const newMenus = [...roleMenus];
        
        // Update the role id of the button
        newMenus[index].colorMessage = event.target.value;
        
        newMenus[index].changed = true;
        
        // Update the state
        setRoleMenus(newMenus);
    }
    const handleToggleExclusive = (index) => {
        // Create a copy of the roleMenus array
        const newMenus = [...roleMenus];
        
        // Update the exclusive value for the selected menu
        newMenus[index].exclusive = !newMenus[index].exclusive;
        
        newMenus[index].changed = true;
        
        // Update the state
        setRoleMenus(newMenus);
    };
    const handleAddButton = (index) => {
        // Create a copy of the roleMenus array
        const newMenus = [...roleMenus];
        
        // Update the exclusive value for the selected menu
        newMenus[index].buttons.push({
            emoji: 'heart',
            roleId: roles[roles.length-1].id
        });
        
        newMenus[index].changed = true;
        
        // Update the state
        setRoleMenus(newMenus);
    }
    const handleRoleIdSelect = (event, menuIndex, buttonIndex) =>{
        // Create a copy of the roleMenus array
        const newMenus = [...roleMenus];
        
        // Update the role id of the button
        newMenus[menuIndex].buttons[buttonIndex].roleId = event.target.value;
        
        newMenus[menuIndex].changed = true;
        
        // Update the state
        setRoleMenus(newMenus);
    };
    const handleEmojiInput = (menuIndex, buttonIndex, value) => {
        // Create a copy of the roleMenus array
        const newMenus = [...roleMenus];
        
        // Update the emoji of the button
        newMenus[menuIndex].buttons[buttonIndex].emoji = value;
        
        newMenus[menuIndex].changed = true;
        
        // Update the state
        setRoleMenus(newMenus);
    };
    const handleRemoveButton = (menuIndex, buttonIndex) => {
        // Create a copy of the roleMenus array
        const newMenus = [...roleMenus];

        // Remove from local state first for immediate UI feedback.
        newMenus[menuIndex].buttons = newMenus[menuIndex].buttons.filter((_, i) => i !== buttonIndex);
        
        newMenus[menuIndex].changed = true;
        
        setRoleMenus(newMenus);
    };
    const roleSubmit = async (e) => {
        e.preventDefault();

        setEmojiErrorMessage(null);
        setRoleErrorMessage(null);
        setMenuSuccessMessage('Creating role menus...');
        
        const payload = {
            guild_id: params.id,
            role_menus: roleMenus
        };
        
        try {
            const response = await axios.post('/api/role-menus', payload);
            
            if(response.status === 200){              
                if(response.data.menuResults){
                    const results = response.data.menuResults;
                    // Create a copy of the roleMenus array
                    let newMenus = [...roleMenus];
                    for(const index in results){

                        newMenus[index].changed = false;

                        newMenus[index].failed = false;
                        if(results[index].failed){
                            newMenus[index].failed = true;
                        } else {
                            newMenus[index].message = results[index].message;
                        }

                        if(roleMenus[index].type === 'role'){
                            for(const buttonIndex in newMenus[index].buttons){
                                newMenus[index].buttons[buttonIndex].invalidEmoji = false;
                                newMenus[index].buttons[buttonIndex].invalidRole = false;
                                for(const invalidEmoji of results[index].invalidEmoji){
                                    if(newMenus[index].buttons[buttonIndex].emoji === invalidEmoji){
                                        newMenus[index].buttons[buttonIndex].invalidEmoji = true;
                                        setEmojiErrorMessage("Invalid Emoji.");
                                    }
                                }
                                for(const invalidRole of results[index].invalidRole){
                                    if(newMenus[index].buttons[buttonIndex].roleId === invalidRole){
                                        newMenus[index].buttons[buttonIndex].invalidRole = true;
                                        setRoleErrorMessage("Invalid role(s).");
                                    }
                                }
                            }
                        }
                    }
                        
                    setRoleMenus(newMenus);
                }               
                
                setMenuSuccessMessage("Successfully sent changes.");
                setMenuErrorMessage(null);
                // Hide the message after 3 seconds
                setTimeout(() => {
                    setMenuSuccessMessage(null);
                }, 3000);
            }            
        } catch (error) {
            // Default error message if none provided from the server
            setMenuErrorMessage("Failed to save the role menus");
            setMenuSuccessMessage(null);
            // Hide the message after 3 seconds
            setTimeout(() => {
                setMenuErrorMessage(null);
            }, 3000);
        }
    };


    let guild;
    if (user){
        if(user.guilds.length > 0){
            let guildInfo = user.guilds.find(guild => guild.id === params.id);
            if(guildInfo){
                const iconUrl = guildInfo.icon 
                    ? `https://cdn.discordapp.com/icons/${guildInfo.id}/${guildInfo.icon}.png` 
                    : defaultGuildIcon;
                guild = (
                    <>
                        <img className='guild-banner' src={iconUrl} alt={`${guildInfo.name} icon`}></img>
                        <h2>{guildInfo.name} Dashboard</h2>

                        <div className='left dashboard-item'>
                            <h3 className='dashboard-item-header'>Basic Settings</h3>
                            <form className='dashboard-form' onSubmit={basicSubmit}>
                                <label className='dashboard-form-label' htmlFor="counting-channel">Counting Game Channel</label>
                                <select
                                    id="counting-channel"
                                    className='dashboard-select'
                                    name="counting-channel"
                                    value={countChannel}
                                    onChange={handleCountChannelChange}
                                >
				                    <option key='0' value='0'>No Counting Game</option>
                                    {channels.map(channel => (
                                        <option key={channel.id} value={channel.id}>
                                            {channel.name}
                                        </option>
                                    ))}
                                </select>

                                <label className='dashboard-form-label' htmlFor="question-channel">Question of the Day Channel</label>
                                <select
                                    id="question-channel"
                                    className='dashboard-select'
                                    name="question-channel"
                                    value={questionChannel}
                                    onChange={handleQuestionChannelChange}
                                >
				                    <option key='0' value='0'>No Question Channel</option>
                                    {channels.map(channel => (
                                        <option key={channel.id} value={channel.id}>
                                            {channel.name}
                                        </option>
                                    ))}
                                </select>

                                <label className='dashboard-form-label' htmlFor="champion-role">Champion Role</label>
                                <select
                                    id="champion-role"
                                    className='dashboard-select'
                                    name="champion-role"
                                    value={championRole}
                                    onChange={handleChampionRoleChange}
                                >
                                    <option key='0' value='0'>No Champion Role</option>
                                    {roles.map(role => (
                                        <option key={role.id} value={role.id}>
                                            {role.name}
                                        </option>
                                    ))}
                                </select>

                                <label className='dashboard-form-label' htmlFor="regular-role">Regular Role</label>
                                <select
                                    id="regular-role"
                                    className='dashboard-select'
                                    name="regular-role"
                                    value={regularRole}
                                    onChange={handleRegularRoleChange}
                                >
                                    <option key='0' value='0'>No Regular Role</option>
                                    {roles.map(role => (
                                        <option key={role.id} value={role.id}>
                                            {role.name}
                                        </option>
                                    ))}
                                </select>

                                <label className='dashboard-form-label' htmlFor="member-role">Member Role</label>
                                <select
                                    id="member-role"
                                    className='dashboard-select'
                                    name="member-role"
                                    value={memberRole}
                                    onChange={handleMemberRoleChange}
                                >
                                    <option key='0' value='0'>No Member Role</option>
                                    {roles.map(role => (
                                        <option key={role.id} value={role.id}>
                                            {role.name}
                                        </option>
                                    ))}
                                </select>

                                <label className='dashboard-form-label' htmlFor="remove-members">Remove Membership From Lurkers</label>
                                <ToggleSwitch name="remove-members" isToggled={removeMembers} onToggle={toggleRemoveMembers} />

                                <div className="dashboard-success">{basicSuccessMessage}</div>
                                <div className="dashboard-error">{basicErrorMessage}</div>

                                <div className='right'>
                                    <button type='submit' className='dashboard-item-submit'>Save</button>
                                </div>
                            </form>
                        </div>

                        <div className='left dashboard-item'>
                            <div className='flex'> 
                                <h3 className='dashboard-item-header'>Welcome Message</h3>
                                <div className='grow'></div>
                                <ToggleSwitch isToggled={isWelcomeEnabled} onToggle={toggleWelcomeMessage} />
                            </div>

                            {isWelcomeEnabled && (<form className='dashboard-form' onSubmit={welcomeSubmit}>
                                <label className='dashboard-form-label' htmlFor="welcome-text">Welcome Message Text</label>
			                    <textarea
                                    id="welcome-text"
                                    name="welcome-text"
                                    spellCheck="true"
                                    maxLength={512}
                                    value={welcomeMessage}
                                    onChange={handleWelcomeMessageChange}
                                    rows='8'
                                    cols='100'
                                ></textarea>
                                <div className='right'>{welcomeMessage.length}/512</div>
                                
                                <label className='dashboard-form-label' htmlFor="welcome-channel">Welcome Channel</label>
                                <select
                                    id="welcome-channel"
                                    className='dashboard-select'
                                    name="welcome-channel"
                                    value={welcomeChannel}
                                    onChange={handleWelcomeChannelChange}
                                >
				                    {channels.map(channel => (
                                        <option key={channel.id} value={channel.id}>
                                            {channel.name}
                                        </option>
                                    ))}
                                </select>
                                
                                <label className='dashboard-form-label' htmlFor="welcome-image">Welcome Image URL</label>
                                <input 
                                    id="welcome-image"
                                    name='welcome-image'
                                    type='text'
                                    size='512'
                                    value={welcomeImage}
                                    onChange={handleWelcomeImageChange}
                                />

                                <div className="dashboard-success">{welcomeSuccessMessage}</div>
                                <div className="dashboard-error">{welcomeErrorMessage}</div>

                                <div className='right'>
                                    <button type='submit' className='dashboard-item-submit'>Save</button>
                                </div>
                            </form>)}
                        </div>

                        <div className='left dashboard-item'>
                            <h3 className='dashboard-item-header'>Role Menus</h3>
                            <form className='dashboard-form' onSubmit={roleSubmit}>
                                {roleMenus.map((menu, index) => (
                                    <div className="role-menu" key={index}>
                                        {menu.message && <div className="role-menu-message role-menu-row">
                                            Message Id: {menu.message}
                                        </div>}

                                        {menu.failed && <div className="role-menu-message invalid role-menu-row">Failed making message.</div>}

                                        <div className="flex role-menu-row">
                                            <select
                                                className='role-menu-select'
                                                name="role-menu-channel"
                                                value={menu.channel}
                                                disabled={menu.message}
                                                onChange={(e) => handleRoleMenuSelectChange(e, index)}
                                            >
                                                {channels.map(channel => (
                                                    <option key={channel.id} value={channel.id}>
                                                        {channel.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <div className="grow"></div>
                                            <div className="remove-menu-wrapper">
                                                <i className="icon-bin remove-menu" onClick={() => handleRemoveMenu(index, menu.message)}></i>
                                            </div>
                                        </div>
                                        
                                        <div className="flex role-menu-row">
                                            <div className="grow">
                                                <select
                                                    className='role-menu-select'
                                                    name="role-menu-type"
                                                    value={menu.type}
                                                    disabled={menu.message}
                                                    onChange={(e) => handleRoleMenuTypeChange(e, index)}
                                                >
                                                    <option key='color' value='color'>Color</option>
                                                    <option key='role' value='role'>Role</option>
                                                </select>

                                                {menu.type === 'color' && 
                                                    <div>
                                                        <label className='role-message-label' htmlFor="color-message">Welcome Message Text:</label>
                                                        <textarea
                                                            class="role-menu-message"
                                                            name="color-message"
                                                            spellCheck="true"
                                                            maxLength={1024}
                                                            value={roleMenus[index].colorMessage}
                                                            onChange={(e) => {handleColorMessage(e, index)}}
                                                            rows='8'
                                                            cols='100'
                                                        ></textarea>
                                                        <div className='right'>{roleMenus[index].colorMessage.length}/1024</div>
                                                    </div>
                                                }

                                                {menu.type === 'role' && 
                                                    <div className="flex role-menu-button-item">
                                                        <label htmlFor="exclusive" className='role-exclusive-label'>Exclusive:</label>
                                                        <ToggleSwitch
                                                            name="exclusive"
                                                            isToggled={menu.exclusive}
                                                            onToggle={() => {handleToggleExclusive(index)}}
                                                        />
                                                        <div className="grow"></div>
                                                        <div>
                                                            <i className="icon-plus add-role" onClick={() => handleAddButton(index)}></i>
                                                        </div>
                                                    </div>
                                                }

                                                {menu.type === 'role' && menu.buttons.map((button, buttonIndex) => (
                                                    <div key={buttonIndex} className="flex role-menu-button">
                                                        <div className="grow">
                                                            <div className="flex role-menu-button-item">
                                                                {!button.invalidRole && <label htmlFor="role-select">Role</label>}
                                                                {button.invalidRole && <label htmlFor="role-select" className="invalid">Role</label>}
                                                                <select
                                                                    className='role-select'
                                                                    name='role-select'
                                                                    value={button.roleId}
                                                                    onChange={(e) => {handleRoleIdSelect(e, index, buttonIndex)}}
                                                                >
                                                                    {roles.map(role => (
                                                                        <option key={role.id} value={role.id}>
                                                                            {role.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="role-menu-button-item">
                                                                {!button.invalidEmoji && <label htmlFor="emoji">Emoji</label>}
                                                                {button.invalidEmoji && <label htmlFor="emoji" className="invalid">Emoji</label>}
                                                                <EmojiInput startingValue={button.emoji} onInput={(value) => {handleEmojiInput(index, buttonIndex, value)}} />
                                                            </div>
                                                            <hr></hr>
                                                        </div>
                                                        <div className="remove-button-wrapper">
                                                            <i className="icon-bin remove-button" onClick={() => handleRemoveButton(index, buttonIndex)}></i>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <div className="add-menu-wrapper">
                                    <i className="icon-plus add-menu" onClick={handleAddMenu}></i>
                                </div>

                                <div className="dashboard-message">{menuSuccessMessage}</div>
                                <div className="dashboard-error">{menuErrorMessage}</div>
                                <div className="dashboard-error">{emojiErrorMessage}</div>
                                <div className="dashboard-error">{roleErrorMessage}</div>

                                <div className='right'>
                                    <button type='submit' className='dashboard-item-submit'>Save</button>
                                </div>
                            </form>
                        </div>

                    </>
                );
            } else {
                guild = <p>You do not manage this guild.</p>
            }
        } else{
            guild = <p>You do not manage this guild.</p>
        }
    } else {
        guild = <p>You are not signed in.</p>;
    }
    
    return guild;
};

export default Guild;