import '../css/Home.css';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import image1 from '../assets/images/stonks.png';
import image2 from '../assets/images/cats.png';
import image3 from '../assets/images/blackjack.png';

const slides = [
  { img: image1, description: "Earn in bot currency called Scritch Bucks!" },
  { img: image2, description: "Collect cats which unlock new commands!" },
  { img: image3, description: "Play games such as blackjack!" }
];

 // Slider settings
 const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true
};


const Home = () => {
  return (
    <div className='home-wrapper'>
      <div className="carousel-wrapper">
        <Slider {...settings}>
          {slides.map((slide, index) => (
            // Carousel items
            <div key={index}>
              <h2 className='carousel-header'>{slide.description}</h2>
              <img src={slide.img} alt={`carousel-${index}`} className='carousel-image' />
            </div>
          ))}
        </Slider>
      </div>
      <a href="https://discord.com/api/oauth2/authorize?client_id=974462970220408902&permissions=8&scope=bot%20applications.commands">Add Aineko to your server.</a>
      <p>Aineko is a multi purpose Discord bot that tracks user activity and allows users to earn an in bot currency called Scritch Bucks. Aineko can also create role menus, assign name colors, generate AI images, play games, and more!</p>
    </div>
  );
};

export default Home;
