import '../css/Footer.css';

export default function Footer(){
    return (
        <footer className="footer">
            <a href="https://www.patreon.com/monkeymakes" target="_blank" rel="noreferrer">The Patreon</a>
            <a href="/TermsOfService.html" target="_blank" rel="noreferrer">Terms of Service</a>
            <a href="/PrivacyPolicy.html" target="_blank" rel="noreferrer">Privacy Policy</a>
        </footer>
    )
}