import { createContext, useState, useEffect } from 'react';

const UserContext = createContext(null);

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Function to clear the user state
  const clearUser = () => {
    setUser(null);
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch('/api/user', {
          credentials: 'include', // Include cookies with the request
        });
        const data = await response.json();
        
        if (response.ok) {
          setUser(data);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUser();
  }, []);

  return <UserContext.Provider value={{user, clearUser}}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };