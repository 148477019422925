// Converts a MySQL timestamp into a JavaScript Date object.
// @param {string} mysqlTimestamp - The MySQL timestamp as a string (YYYY-MM-DD HH:MM:SS).
// @returns {Date} A JavaScript Date object representing the timestamp.

export function formatDate(dateObj) {
  // Array of short month names
  const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // Getting the month and using it to find the corresponding short month name
  const month = shortMonths[dateObj.getMonth()];

  // Getting the day of the month
  const date = dateObj.getDate();

  // Getting the full year
  const year = dateObj.getFullYear();

  // Concatenating the parts together in the desired format
  return `${month} ${date}, ${year}`;
}