import '../css/MultiRangeSlider.css';
import React, { useState, useEffect, useRef } from 'react';

const MultiRangeSlider = ({ min, max, onChange }) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);

  useEffect(() => {
    const minPercent = ((minVal - min) / (max - min)) * 100;
    const maxPercent = ((maxVal - min) / (max - min)) * 100;

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }

    if (onChange) {
      onChange([minVal, maxVal]);
    }
  }, [minVal, maxVal, min, max, onChange]);

  return (
    <div className="multi-range-slider">
        <div className="slider-container">
            <div className="slider__track"></div>
            <div ref={range} className="slider__range"></div>
        </div>
        <input
            type="range"
            min={min}
            max={max}
            value={minVal}
            onChange={(event) => {
                const value = Math.min(Number(event.target.value), maxVal - 1);
                setMinVal(value);
                minValRef.current = value;
            }}
            className="thumb thumb--left"
        />
        <input
            type="range"
            min={min}
            max={max}
            value={maxVal}
            onChange={(event) => {
                const value = Math.max(Number(event.target.value), minVal + 1);
                setMaxVal(value);
                maxValRef.current = value;
            }}
            className="thumb thumb--right"
        />
    </div>
  );
};

export default MultiRangeSlider;
